import { http } from '@/http/axios.js'
// 部门树
export function getDeptTree() {
  return http({
    url: `/admin/dept/tree`,
    method: 'get'
  })
}
// 删除部门
export function removeDept(id) {
  return http({
    url: `/admin/dept/delete`,
    method: 'post',
    data: { id }
  })
}
// 新增部门
export function addDept(data) {
  return http({
    url: `/admin/dept/add`,
    method: 'post',
    data
  })
}
// 编辑部门
export function editDept(data) {
  return http({
    url: `/admin/dept/edit`,
    method: 'put',
    data
  })
}
// 部门详情
export function getDeptDetail(id) {
  return http({
    url: `/admin/dept/detail`,
    method: 'get',
    params: { id }
  })
}
